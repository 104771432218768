import styled from 'styled-components';

export const Title = styled.div`
  color: #767676;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const InputContainer = styled.div`
  background-color: #fff;
  border-radius: 5px;
  height: 30px;
  margin-top: 8px;
  display: flex;
  padding: 0px 10px;
`;

export const Input = styled.input`
  width: 100%;
  ::placeholder {
    color: #999;
  }
`;

export const InputErrorMessage = styled.div`
  color: #ff5d5d;
  font-size: 13px;
  margin-top: 5px;
  margin-left: 10px;
`;
