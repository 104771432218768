import { FieldErrors, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import DividingTitle from '../../../shared/DividingTitle';
import QuestionForm from '../../../shared/QuestionForm';
import FormSelect from '../../../shared/FormSelect';
import QuestionTitle from '../../../shared/FormTitle';

import countriesList from '../../../apis/countries/countries-list';
import Divider from '../../../shared/Divider';
import { RegisterFormInput } from '../../../apis/register/form-inputs/register.form-intput';
import * as S from './_styled';

type Props = {
  register: UseFormRegister<RegisterFormInput>;
  errors: FieldErrors<RegisterFormInput>;
  setValue: UseFormSetValue<RegisterFormInput>;
};

export default function BasicInfo({ register, errors, setValue }: Props) {
  return (
    <DividingTitle
      title="Apply for Create Jump Partner Registration"
      subTitle="Please enter your company information"
    >
      <S.Container>
        <QuestionForm
          register={register('companyName')}
          title="Company Name"
          required
          example="TravelC2B"
          placeHolder="Enter your company name"
          errors={errors.companyName}
        />
        <QuestionForm
          register={register('email')}
          title="E-mail"
          required
          example="example@companyname.com"
          placeHolder="Enter your e-mail"
          errors={errors.email}
          inputType="email"
        />
        {/* TODO: Secure text, 검증 확인 요망 */}
        <QuestionForm
          register={register('password')}
          title="Password"
          required
          example="Please enter a password of at least 8 characters in combination of English, numbers, and special characters (@,$,#,!,%*,?,&)."
          placeHolder="Enter your password"
          errors={errors.password}
          inputType="password"
        />
        <QuestionForm
          register={register('confirmPassword')}
          title="Confirm Password"
          required
          example="Please enter password again"
          placeHolder="Enter your password again"
          errors={errors.confirmPassword}
          inputType="password"
        />
        <QuestionForm
          register={register('firstname')}
          title="First Name"
          required
          example="John"
          placeHolder="Enter your first name"
          errors={errors.firstname}
        />
        <QuestionForm
          register={register('lastname')}
          title="Last Name"
          required
          example="Smith"
          placeHolder="Enter your last name"
          errors={errors.lastname}
        />
        <S.CountrySelectDiv>
          <QuestionTitle
            title="Country"
            required
            example="Example : United State"
          />
          <FormSelect
            fieldName="countryCode"
            register={register('countryCode')}
            setValue={setValue}
            placeholder="Select your country"
            options={Object.values(countriesList).map((country) => ({
              key: country.countryCode,
              label: `${country.countryNameLocal} (${country.countryNameEn})`,
              value: country.countryCode,
              searchKeywords: [
                country.countryNameLocal,
                country.countryNameEn,
                country.countryNameEn.toLocaleLowerCase(),
                country.countryNameEn.toUpperCase(),
              ],
              html: (
                <S.CountryNameItemDiv>
                  <S.CountryName>
                    {country.countryNameLocal} ({country.countryNameEn})
                  </S.CountryName>
                </S.CountryNameItemDiv>
              ),
            }))}
          />
        </S.CountrySelectDiv>
        <QuestionForm
          register={register('mainAddress')}
          title="Main Address"
          required
          example="123 Main Street"
          placeHolder="Enter your main address"
          errors={errors.mainAddress}
        />
        <QuestionForm
          register={register('subAddress')}
          title="Sub Address"
          required
          example="101 Residential Lane, Jump Apartments"
          placeHolder="Enter your sub address"
          errors={errors.subAddress}
        />
        <QuestionForm
          register={register('cityName')}
          title="City Name"
          required
          example="New York"
          placeHolder="Enter your city name"
          errors={errors.cityName}
        />
        <QuestionForm
          register={register('postalCode')}
          title="Postal Code"
          required
          example="10001"
          placeHolder="Enter your postal code"
          errors={errors.postalCode}
        />
        <S.CountrySelectDiv>
          <QuestionTitle
            title="Country Calling Code"
            required
            example="Example : +1"
          />
          <FormSelect
            fieldName="countryCallingCode"
            register={register('countryCallingCode')}
            setValue={setValue}
            placeholder="Select your country calling code"
            options={Object.values(countriesList).map((country) => ({
              key: country.countryCode,
              label: `+${country.countryCallingCode} (${country.countryNameLocal})`,
              value: country.countryCallingCode,
              searchKeywords: [
                country.countryCallingCode,
                country.countryNameLocal,
                country.countryNameEn,
                country.countryNameEn.toLocaleLowerCase(),
                country.countryNameEn.toUpperCase(),
                `+${country.countryCallingCode}`,
              ],
              html: (
                <S.CountryCallingCodeItemDiv>
                  <S.CountryName>{country.countryNameLocal}</S.CountryName>
                  <S.CountryCallingCode>
                    +{country.countryCallingCode}
                  </S.CountryCallingCode>
                </S.CountryCallingCodeItemDiv>
              ),
            }))}
          />
        </S.CountrySelectDiv>
        <QuestionForm
          register={register('phoneNumber')}
          title="Phone Number"
          required
          example="1234567890"
          placeHolder="Enter your phone number"
          errors={errors.phoneNumber}
        />
        <Divider />
      </S.Container>
    </DividingTitle>
  );
}
