import styled from 'styled-components';

export const Container = styled.div<{ marginTop: string }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: ${({ marginTop }) => marginTop};
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Title = styled.div`
  font-size: 30px;
  font-weight: 700;
`;

export const SubTitle = styled.div`
  font-size: 15px;
  font-weight: 400;
  color: #767676;
`;
