import styled from 'styled-components';
import RegisterInfoItem from '../RegisterInfoItem';
import { RegisterFormInput } from '../../../apis/register/form-inputs/register.form-intput';

type Props = {
  formData: RegisterFormInput;
};

const Container = styled.div`
  background-color: #f1f1f1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  border-radius: 10px;
`;

export default function RegisterInfo({ formData }: Props) {
  return (
    <Container>
      <RegisterInfoItem title="Company name" value={formData.companyName} />
      <RegisterInfoItem title="E-mail" value={formData.email} />
      <RegisterInfoItem
        title="Name"
        value={`${formData.firstname} ${formData.lastname}`}
      />
      <RegisterInfoItem
        title="Phone Number"
        value={`+${formData.countryCallingCode} ${formData.phoneNumber}`}
      />
    </Container>
  );
}
