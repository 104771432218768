/* eslint-disable max-classes-per-file */
/* eslint-disable import/no-extraneous-dependencies */
import 'reflect-metadata';

/* eslint-disable class-methods-use-this */
// eslint-disable-next-line max-classes-per-file
import {
  IsArray,
  IsEmail,
  IsEnum,
  IsObject,
  IsOptional,
  IsString,
  Length,
  Matches,
  Validate,
  ValidateNested,
  ValidatorConstraint,
  ValidatorConstraintInterface,
} from 'class-validator';
import { Type } from 'class-transformer';
import {
  CountryCode,
  countryCode,
} from '../../countries/constatns/country-code.enum';
import passwordFormat from '../../../formatters/password.format';
import { DeliveryAccount } from '../../delivery/form-inputs/delivery-account-input';
import { CustomsClearance } from '../../customsClearance/form-inputs/custom-clearance-input';
import { DeliveryAddress } from '../../delivery/form-inputs/delivery-address-input';

@ValidatorConstraint({ name: 'passwordMatch', async: false })
class PasswordMatchValidator implements ValidatorConstraintInterface {
  validate(value: any, args: any) {
    const { object } = args;
    return value === object.password;
  }

  defaultMessage() {
    return 'Password is not matching';
  }
}

export class RegisterFormInput {
  @IsString()
  @Length(1, 200, { message: 'Company name required' })
  companyName!: string;

  @IsEmail({}, { message: 'E-mail must E-mail format' })
  @Length(1, 254, { message: 'E-mail required' })
  email!: string;

  @IsString()
  @Matches(passwordFormat, {
    message:
      'Please enter a password of at least 8 characters in combination of English, numbers, and special characters (@,$,#,!,%*,?,&).',
  })
  password!: string;

  @IsString()
  @Validate(PasswordMatchValidator)
  confirmPassword!: string;

  @IsString()
  @Length(1, 50, { message: 'First name required' })
  firstname!: string;

  @IsString()
  @Length(1, 50, { message: 'Last name required' })
  lastname!: string;

  @IsEnum(countryCode, { message: 'Country code err' })
  countryCode!: CountryCode;

  @IsString()
  @Length(1, 1000, { message: 'Main Address required' })
  mainAddress!: string;

  @IsString()
  @Length(1, 1000, { message: 'Sub Address required' })
  subAddress!: string;

  @IsString()
  @Length(1, 200, { message: 'City name required' })
  cityName!: string;

  @IsString()
  @Length(1, 50, { message: 'Postal code required' })
  postalCode!: string;

  @IsString()
  @Length(1, 10)
  countryCallingCode!: string;

  @IsString()
  @Length(1, 20, { message: 'Phone number required' })
  phoneNumber!: string;

  @IsString()
  @Length(1, 100, { message: 'VAT info required' })
  vatNumber!: string;

  @IsOptional()
  @IsObject()
  @ValidateNested({ each: true })
  @Type(() => DeliveryAccount)
  deliveryAccount?: DeliveryAccount;

  @IsArray()
  @ValidateNested({ each: true })
  @Type(() => DeliveryAddress)
  deliveryAddress!: DeliveryAddress[];

  @IsObject()
  @ValidateNested({ each: true })
  @Type(() => CustomsClearance)
  customsClearance!: CustomsClearance;
}

export type RegisterFormInputKeys = keyof RegisterFormInput;
