import { UseFormGetValues, UseFormRegisterReturn } from 'react-hook-form';
import * as S from './_styled';

type Props = {
  type: 'delivery' | 'tax';
  value: any;
  register: UseFormRegisterReturn; // register 자체를 받음
};

export default function FormRadioLabel({ type, value, register }: Props) {
  return (
    <S.RadioLabel htmlFor={`${value}_${type}`} key={value}>
      <S.RadioInput
        id={`${value}_${type}`}
        type="radio"
        value={value}
        {...register}
      />
      {value}
    </S.RadioLabel>
  );
}
