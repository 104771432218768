import axios from 'axios';

export const baseApiClient = axios.create({
  baseURL: 'https://platform-api.createjump.com',
});

baseApiClient.interceptors.request.use((request) => {
  return request;
});

async function axiosInterceptor({ ...options }): Promise<any> {
  const onSuccess = (response: any) => response;

  const onError = (error: any) => {
    return Promise.reject(error);
  };

  try {
    const response = await baseApiClient(options);
    return onSuccess(response);
  } catch (error) {
    return onError(error);
  }
}

export default axiosInterceptor;
