import { UseFormRegisterReturn } from 'react-hook-form';

import * as S from './_styled';

type Props = {
  register: UseFormRegisterReturn;
  inputType?: string;
  placeHolder?: string;
};

export default function FormInput({
  register,
  inputType = 'text',
  placeHolder,
}: Props) {
  return (
    <S.FormContainer>
      <S.Input
        {...register}
        placeholder={placeHolder}
        autoComplete="off"
        type={inputType}
      />
    </S.FormContainer>
  );
}
