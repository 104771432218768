import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 32px;
  flex-direction: column;
  width: 100%;
  margin-top: 52px;
`;

export const CountrySelectDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
`;

export const CountryNameItemDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
`;

export const CountryCallingCodeItemDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
`;

export const CountryName = styled.span`
  font-size: 14px;
  color: #2f2f2f;
`;

export const CountryCallingCode = styled.span`
  font-size: 14px;
  color: #999;
`;
