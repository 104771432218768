import * as S from './_styled';

type Props = {
  title: string;
  required: boolean;
  example?: string;
  marginTop?: string;
};

export default function QuestionTitle({
  title,
  required,
  example,
  marginTop = '0px',
}: Props) {
  return (
    <S.TitleContainer marginTop={marginTop}>
      <S.Row>
        <S.Title>{title}</S.Title> {required && <S.Star>*</S.Star>}
      </S.Row>
      {example && <S.ExampleText>{example}</S.ExampleText>}
    </S.TitleContainer>
  );
}
