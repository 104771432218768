import { FieldErrors, UseFormRegister } from 'react-hook-form';
import DividingTitle from '../../../shared/DividingTitle';
import QuestionForm from '../../../shared/QuestionForm';
import QuestionTitle from '../../../shared/FormTitle';
import { RegisterFormInput } from '../../../apis/register/form-inputs/register.form-intput';

import * as S from './_styled';
import FormRadioLabel from '../../../shared/FormRadioLabel';
import useGetClearance from '../../../apis/customsClearance/queries/useGetCustomsClearance';

type Props = {
  register: UseFormRegister<RegisterFormInput>;
  errors: FieldErrors<RegisterFormInput>;
};

export default function VATInfo({ register, errors }: Props) {
  const { clearanceData, error } = useGetClearance();

  return (
    <div>
      <DividingTitle
        title="Your Customs Clearance And VAT Information"
        subTitle="Please enter your customs clearance and VAT information"
        marginTop="52px"
      />
      <S.Container>
        <QuestionForm
          register={register('vatNumber')}
          title="VAT Number"
          required
          example="VAT12345678"
          placeHolder="Enter your VAT number"
          errors={errors.vatNumber}
        />
        <div>
          <QuestionTitle title="Customs Clearance Type" required />
          <S.RadioGroup>
            {clearanceData?.customsClearanceList &&
              clearanceData.customsClearanceList.map((item) => (
                <FormRadioLabel
                  key={item.id}
                  type="tax"
                  value={item.numberType}
                  register={register('customsClearance.type')}
                />
              ))}
          </S.RadioGroup>
        </div>
        <QuestionForm
          register={register('customsClearance.number')}
          title="Customs Clearance Number"
          required
          example="Type12345678"
          placeHolder="Enter customs clearance number"
          errors={errors.customsClearance?.number}
        />
      </S.Container>
    </div>
  );
}
