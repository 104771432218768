/* eslint-disable consistent-return */
/* eslint-disable no-alert */
import { UseMutationResult, useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { baseApiClient } from '../../../libs/api-client';
import { RegisterFormInput } from '../form-inputs/register.form-intput';
import { CoreOutput } from '../../common/dtos/output.dto';

const createUserRegister = async (input: RegisterFormInput) => {
  const result = await baseApiClient({
    url: `/v1/b2b/partners/request-application`,
    method: 'POST',
    data: input,
  });

  return result.data;
};

export function createUserRegisterMutation(): UseMutationResult<
  CoreOutput<never>,
  AxiosError<CoreOutput<never>>,
  RegisterFormInput
> {
  return useMutation(createUserRegister);
}

export default function useCreateUserRegister() {
  const { mutateAsync } = createUserRegisterMutation();

  const createUserRegisterMutate = async (
    createUserRegisterInput: RegisterFormInput,
  ) => {
    try {
      const result = await mutateAsync(createUserRegisterInput);

      if (result.ok === true) {
        return result;
      }
    } catch (error) {
      alert(
        'Error: An error occurred while registration. Please contact the admin.',
      );
    }
  };

  return {
    createUserRegisterMutate,
  };
}
