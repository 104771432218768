/* eslint-disable import/no-absolute-path */
import { FieldError, UseFormRegisterReturn } from 'react-hook-form';
import * as S from './_styled';

type Props = {
  title: string;
  placeHolder?: string;
  register: UseFormRegisterReturn;
  errors?: FieldError;
};
export default function FormField({
  title,
  placeHolder,
  register,
  errors,
}: Props) {
  return (
    <S.ItemContainer>
      <S.Title>{title}</S.Title>
      <S.InputContainer>
        <S.Input
          {...register}
          autoComplete="off"
          type="text"
          placeholder={placeHolder}
        />
      </S.InputContainer>
      {errors && <S.InputErrorMessage>{errors.message}</S.InputErrorMessage>}
    </S.ItemContainer>
  );
}
