import styled from 'styled-components';

export const SelectDiv = styled.div`
  position: relative;
`;

export const SelectOptionOpenBtn = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: #f2f2f2;
  border-radius: 10px;
  padding: 0 20px;
`;

export const SelectedOptionOrKeywordInput = styled.input`
  flex: 1;
  padding: 18px 0;
  font-size: 14px;
  color: #2f2f2f;
  text-align: left;

  &::placeholder {
    color: #999;
  }
`;

export const HiddenInput = styled.input`
  display: none;
`;

export const OptionDiv = styled.div`
  background-color: #f2f2f2;
  border-radius: 10px;
  position: absolute;
  top: 62px;
  left: 0;
  width: 100%;
  box-shadow: 0px 3px 6px #00000029;
  z-index: 1;
`;

export const OptionList = styled.ul`
  display: flex;
  flex-flow: column;
  max-height: 250px;
  overflow-y: auto;
  margin: 2px 0;
`;

export const OptionItem = styled.li`
  padding: 16px 20px;
  cursor: pointer;
  font-size: 14px;
  color: #999;
`;
