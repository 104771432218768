import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 32px;
  flex-direction: column;
  width: 100%;
  margin-top: 52px;
`;

export const RadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 8px;
`;

export const RadioInput = styled.input`
  margin-right: 5px;
`;

export const RadioLabel = styled.label`
  cursor: pointer;
`;
