import * as S from './_styled';
import { ReactComponent as Logo } from './ic_createJump_logo.svg';

function Header() {
  return (
    <S.Container>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="128px"
        height="22px"
        viewBox="0 0 128 22"
      >
        <Logo />
      </svg>
    </S.Container>
  );
}

export default Header;
