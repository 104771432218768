import { useForm } from 'react-hook-form';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { useNavigate } from 'react-router-dom';
import { RegisterFormInput } from '../../apis/register/form-inputs/register.form-intput';
import SubmitButton from '../../shared/SubmitButton';

import BasicInfo from './BasicInfo';
import DeliveryInfo from './DeliveryInfo';
import VATInfo from './VATInfo';
import useCreateUserRegister from '../../apis/register/mutation/useCreateUserRegister';

export default function PartnerForm() {
  const navigate = useNavigate();
  const { createUserRegisterMutate } = useCreateUserRegister();

  const {
    register,
    handleSubmit,
    watch,
    control,
    resetField,
    setValue,
    formState: { isValid, errors },
  } = useForm<RegisterFormInput>({
    mode: 'all',
    resolver: classValidatorResolver(RegisterFormInput),
    defaultValues: {
      deliveryAddress: [{ alias: '', mainAddress: '', subAddress: '' }],
    },
  });

  const onSubmit = async (data: RegisterFormInput) => {
    const result = await createUserRegisterMutate({
      companyName: data.companyName,
      email: data.email,
      password: data.password,
      confirmPassword: data.confirmPassword,
      firstname: data.firstname,
      lastname: data.lastname,
      countryCode: data.countryCode,
      mainAddress: data.mainAddress,
      subAddress: data.subAddress,
      cityName: data.cityName,
      postalCode: data.postalCode,
      countryCallingCode: data.countryCallingCode,
      phoneNumber: data.phoneNumber,
      deliveryAddress: data.deliveryAddress,
      vatNumber: data.vatNumber,
      customsClearance: data.customsClearance,
      ...(data.deliveryAccount?.deliveryCompany !== 'None' && {
        deliveryAccount: data.deliveryAccount,
      }),
    });

    if (result?.ok === true) {
      navigate('/complete', {
        replace: true,
        state: { formData: data },
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <BasicInfo register={register} errors={errors} setValue={setValue} />
      <DeliveryInfo
        register={register}
        watch={watch}
        setValue={setValue}
        resetField={resetField}
        control={control}
        errors={errors}
      />
      <VATInfo register={register} errors={errors} />
      <SubmitButton title="SUBMIT" type="submit" disabled={isValid === false} />
    </form>
  );
}
