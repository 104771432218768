import styled from 'styled-components';
import { ReactComponent as CompleteIcon } from '../../icons/ic_complete.svg';
import RegisterInfo from './RegisterInfo';
import { RegisterFormInput } from '../../apis/register/form-inputs/register.form-intput';

type Props = {
  formData: RegisterFormInput;
};

const Container = styled.div`
  width: 100%;
  max-width: 426px;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 74px;
  gap: 32px;
`;

const LargeTitle = styled.h2`
  text-align: center;
  font-size: 30px;
  font-weight: 700;
`;

const SmallTittle = styled.h3`
  text-align: center;
  font-size: 15px;
  white-space: pre-line;
  line-height: 140%;
  color: #767676;
`;

export default function CompleteInfo({ formData }: Props) {
  return (
    <Container>
      <CompleteIcon />
      <LargeTitle>Your registration has been completed</LargeTitle>
      <SmallTittle>
        Registration confirm may take a few days.
        <br />
        After confirm, you can use Create Jump B2B Service.
      </SmallTittle>
      <RegisterInfo formData={formData} />
    </Container>
  );
}
