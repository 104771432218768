import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const InputErrorMessage = styled.div`
  color: #ff5d5d;
  font-size: 13px;
  margin-top: 5px;
  margin-left: 10px;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
