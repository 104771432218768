import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ResetStyles from './styles/reset-styles';
import GlobalStyles from './styles/global-styles';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <ResetStyles />
    <GlobalStyles />
    <App />
  </React.StrictMode>,
);

reportWebVitals();
