import styled from 'styled-components';
import Header from '../components/Header';
import PartnerForm from '../components/Form';
import DevHiddenButton from '../components/DevHidden/Button';

const SuperView = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Container = styled.div`
  width: 100%;
  max-width: 426px;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 74px;
`;

function Home() {
  return (
    <SuperView>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
      />
      <Header />
      <Container>
        <PartnerForm />
        <DevHiddenButton />
      </Container>
    </SuperView>
  );
}

export default Home;
