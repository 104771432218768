import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import Header from '../components/Header';
import CompleteInfo from '../components/CompleteInfo';
import { RegisterFormInput } from '../apis/register/form-inputs/register.form-intput';

const SuperView = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

function Complete() {
  const location = useLocation();

  const formData = location.state.formData as RegisterFormInput;

  return (
    <SuperView>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
      />
      <Header />
      <CompleteInfo formData={formData} />
    </SuperView>
  );
}

export default Complete;
