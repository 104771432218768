import { IsString, Length } from 'class-validator';

export class DeliveryAddress {
  @IsString()
  @Length(1, 100)
  alias!: string;

  @IsString()
  @Length(1, 1000)
  mainAddress!: string;

  @IsString()
  @Length(1, 1000)
  subAddress!: string;
}
