function Dev() {
  return (
    <div>
      <img
        src="https://create-jump-s3.s3.ap-northeast-2.amazonaws.com/test/10.7mb.png"
        alt="SampleImg"
      />
    </div>
  );
}

export default Dev;
