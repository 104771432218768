import styled from 'styled-components';

export const RadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 8px;
`;

export const RadioInput = styled.input`
  margin-right: 5px;
`;

export const DeliveryInfoText = styled.div`
  display: flex;
  flex-flow: column;
  gap: 2px;
  margin-top: 4px;
  margin-left: 10px;

  & > p {
    font-size: 13px;
    color: #767676;
    font-weight: 400;
  }
`;

export const ExtraDeliveryInfoDiv = styled.div`
  display: flex;
  flex-flow: column;
  gap: 32px;
`;

export const FormFieldContainer = styled.div`
  background-color: #f1f1f1;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 10px;
`;

export const FormFieldDelDiv = styled.button`
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  margin-top: 20px;
`;
