import { IsString, Length } from 'class-validator';

export class CustomsClearance {
  @IsString()
  type!: string;

  @IsString()
  @Length(1, 100, { message: 'Custom clearance number required' })
  number!: string;
}
