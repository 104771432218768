import styled from 'styled-components';

export const RadioLabel = styled.label`
  cursor: pointer;
  margin-left: 10px;
`;

export const RadioInput = styled.input`
  margin-right: 5px;
`;
