import React, { useEffect, useState } from 'react';
import { UseFormRegisterReturn, UseFormSetValue } from 'react-hook-form';
import OutsideClickHandler from 'react-outside-click-handler';

import * as S from './_styled';
import useInputTypeText from '../../hooks/useInputTypeText';

export type FormSelectOptionType = {
  key: string;
  label: string;
  value: string;
  searchKeywords: string[];
  html?: React.ReactNode;
};

interface FormSelectProps {
  fieldName: string;
  register: UseFormRegisterReturn;
  setValue: UseFormSetValue<any>;
  options: FormSelectOptionType[];
  placeholder?: string;
  allowSearch?: boolean;
}

export default function FormSelect({
  fieldName,
  register,
  setValue,
  options,
  placeholder = '',
  allowSearch = true,
}: FormSelectProps) {
  const [isOptionsOpend, setIsOptionsOpend] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<string>('');
  const [searchKeyword, onChangeSearchKeyword, setSearchKeyword] =
    useInputTypeText('');

  const onChangeValue = (label: string, value: string) => () => {
    setValue(fieldName, value, { shouldValidate: true });
    setSearchKeyword('');
    setSelectedOption(label);
    setIsOptionsOpend(false);
  };

  const onOutsideClick = () => {
    setSearchKeyword('');
    setIsOptionsOpend(false);
  };

  const [filteredOptions, setFilteredOptions] =
    useState<FormSelectOptionType[]>(options);
  useEffect(() => {
    const copyOptions = [...options];
    setFilteredOptions(
      copyOptions.filter((option) => {
        let isSatisfied = false;
        // eslint-disable-next-line no-restricted-syntax
        for (const optionKeyword of option.searchKeywords) {
          if (optionKeyword.indexOf(searchKeyword) !== -1) {
            isSatisfied = true;
          }
        }
        return isSatisfied;
      }),
    );
  }, [searchKeyword]);

  return (
    <S.SelectDiv>
      <S.SelectOptionOpenBtn
        type="button"
        onClick={() => setIsOptionsOpend(true)}
      >
        <S.SelectedOptionOrKeywordInput
          value={isOptionsOpend === true ? searchKeyword : selectedOption}
          placeholder={
            isOptionsOpend === true
              ? selectedOption || placeholder
              : placeholder
          }
          autoComplete="off"
          onChange={onChangeSearchKeyword}
          readOnly={allowSearch === false}
        />
        <S.HiddenInput
          {...register}
          type="text"
          readOnly
          required
          placeholder={placeholder}
          autoComplete="off"
        />
        <img src="/search.svg" alt="Search icon" />
      </S.SelectOptionOpenBtn>
      {isOptionsOpend === true && (
        <OutsideClickHandler onOutsideClick={onOutsideClick}>
          <S.OptionDiv>
            <S.OptionList className="scroll-component">
              {filteredOptions.length === 0 ? (
                <S.OptionItem>No search results</S.OptionItem>
              ) : (
                filteredOptions.map((option) => (
                  <S.OptionItem
                    key={option.key}
                    onClick={onChangeValue(option.label, option.value)}
                  >
                    {option.html || option.label}
                  </S.OptionItem>
                ))
              )}
            </S.OptionList>
          </S.OptionDiv>
        </OutsideClickHandler>
      )}
    </S.SelectDiv>
  );
}
