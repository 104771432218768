import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from '../pages/home';
import Complete from '../pages/complete';
import Dev from '../pages/dev';

function AppRouter() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/complete" element={<Complete />} />
        <Route path="/dev" element={<Dev />} />
      </Routes>
    </Router>
  );
}

export default AppRouter;
