import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const Button = styled.button`
  height: 10px;
  width: 100%;
  background-color: #fffff1;
`;

export default function DevHiddenButton() {
  const navigate = useNavigate();

  const onClick = () => {
    navigate('/dev', { replace: true });
  };
  return <Button onClick={onClick} />;
}
