import {
  Control,
  FieldErrors,
  UseFormRegister,
  UseFormResetField,
  UseFormSetValue,
  UseFormWatch,
  useFieldArray,
} from 'react-hook-form';
import { useEffect } from 'react';
import DividingTitle from '../../../shared/DividingTitle';
import QuestionTitle from '../../../shared/FormTitle';
import { RegisterFormInput } from '../../../apis/register/form-inputs/register.form-intput';
import FormInput from '../../../shared/FormInput';
import Divider from '../../../shared/Divider';
import * as S from './_styled';
import FormRadioLabel from '../../../shared/FormRadioLabel';
import FormField from '../../../shared/FormField';
import SubmitButton from '../../../shared/SubmitButton';

import { ReactComponent as Del } from '../../../icons/del.svg';

type Props = {
  register: UseFormRegister<RegisterFormInput>;
  watch: UseFormWatch<RegisterFormInput>;
  setValue: UseFormSetValue<RegisterFormInput>;
  resetField: UseFormResetField<RegisterFormInput>;
  control: Control<RegisterFormInput, any>;
  errors: FieldErrors<RegisterFormInput>;
};

export default function DeliveryInfo({
  register,
  watch,
  setValue,
  resetField,
  control,
  errors,
}: Props) {
  useEffect(() => {
    setValue('deliveryAccount.deliveryCompany', 'None');
    setValue('deliveryAccount.accountNumber', '');
  }, []);

  useEffect(() => {
    if (watch('deliveryAccount.deliveryCompany') === 'None') {
      setValue('deliveryAccount.accountNumber', '');
      resetField('deliveryAccount.extra');
    } else {
      resetField('deliveryAccount.accountNumber');
      resetField('deliveryAccount.extra');
    }
  }, [watch('deliveryAccount.deliveryCompany')]);

  const { fields, append, remove } = useFieldArray({
    name: 'deliveryAddress',
    control,
  });

  const onAppend = () => {
    append({
      alias: '',
      mainAddress: '',
      subAddress: '',
    });
  };

  const onDelete = (index: number) => {
    remove(index);
  };

  return (
    <div>
      <DividingTitle
        title="Your Delivery Account Information"
        subTitle="If you have any of the shipping companies below that you will use to do business with us, please choose and enter the information."
        marginTop="52px"
      />
      <QuestionTitle
        title="Delivery Company"
        required={false}
        marginTop="52px"
      />
      <S.RadioGroup>
        {['None', 'FEDEX', 'DHL'].map((item) => (
          <FormRadioLabel
            key={item}
            type="delivery"
            value={item}
            register={register('deliveryAccount.deliveryCompany')}
          />
        ))}
        {watch('deliveryAccount.deliveryCompany') === 'FEDEX' && (
          <S.ExtraDeliveryInfoDiv>
            <div>
              <QuestionTitle title="Fedex Account Number" required={false} />
              <FormInput
                register={register('deliveryAccount.accountNumber')}
                placeHolder="ex) Fedex1234567890"
              />
            </div>
            <div>
              <QuestionTitle title="Fex Account Number" required={false} />
              <FormInput
                register={register(
                  'deliveryAccount.extra.fedexCustomsFEXNumber',
                )}
                placeHolder="ex) fex0987654321"
              />
            </div>
          </S.ExtraDeliveryInfoDiv>
        )}

        {watch('deliveryAccount.deliveryCompany') === 'DHL' && (
          <S.ExtraDeliveryInfoDiv>
            <div>
              <QuestionTitle title="DHL Account Number" required={false} />
              <FormInput
                register={register('deliveryAccount.accountNumber')}
                placeHolder="ex) DHL1234567890"
              />
            </div>
            <div>
              <QuestionTitle title="DHL Account For Taxes" required={false} />
              <FormInput
                register={register('deliveryAccount.extra.dhlAccountForTaxes')}
                placeHolder="ex) dhl0987654321"
              />
            </div>
          </S.ExtraDeliveryInfoDiv>
        )}
      </S.RadioGroup>
      <QuestionTitle title="Delivery Address" required marginTop="32px" />
      {fields.map((field, index) => {
        return (
          <div key={field.id}>
            {index !== 0 && (
              <S.FormFieldDelDiv type="button" onClick={() => onDelete(index)}>
                <Del />
              </S.FormFieldDelDiv>
            )}
            <S.FormFieldContainer>
              <FormField
                title="Alias"
                placeHolder="Example: My Company 1"
                register={register(`deliveryAddress.${index}.alias`)}
                errors={errors.deliveryAddress?.[index]?.alias}
              />
              <FormField
                title="Main Address"
                placeHolder="Example: 123 Main Street"
                register={register(`deliveryAddress.${index}.mainAddress`)}
                errors={errors.deliveryAddress?.[index]?.mainAddress}
              />
              <FormField
                title="Sub Address"
                placeHolder="Example : 101 Residential Lane, Jump Apartments"
                register={register(`deliveryAddress.${index}.subAddress`)}
                errors={errors.deliveryAddress?.[index]?.subAddress}
              />
            </S.FormFieldContainer>
          </div>
        );
      })}
      <SubmitButton
        title="Add more address"
        type="button"
        disabled={false}
        onClick={onAppend}
      />
      <Divider marginTop="32px" />
    </div>
  );
}
