import * as S from './_styled';

type Props = {
  title: string;
  subTitle: string;
  marginTop?: string;
  children?: React.ReactNode;
};

export default function DividingTitle({
  title,
  subTitle,
  marginTop = '0px',
  children,
}: Props) {
  return (
    <S.Container marginTop={marginTop}>
      <S.TitleContainer>
        <S.Title>{title}</S.Title>
        <S.SubTitle>{subTitle}</S.SubTitle>
      </S.TitleContainer>
      {children}
    </S.Container>
  );
}
