import { IsEnum, IsOptional, IsString } from 'class-validator';
import {
  DeliveryCompanies,
  deliveryCompanies,
} from '../constants/delivery-company.enum';
import { B2bPartnerDeliveryAccountExtra } from './delivery-account-extra-input';

export class DeliveryAccount {
  @IsEnum(deliveryCompanies)
  deliveryCompany!: DeliveryCompanies;

  @IsString()
  accountNumber!: string;

  @IsOptional()
  extra?: B2bPartnerDeliveryAccountExtra;
}

export type DeliveryAccountFormInputKeys = keyof DeliveryAccount;
