import { UseQueryResult, useQuery } from 'react-query';

import { AxiosError } from 'axios';
import { baseApiClient } from '../../../libs/api-client';
import { GetClearanceOutput } from '../dtos/get-clearance.dto';

const getClearance = async () => {
  const result = await baseApiClient({
    url: `/v1/b2b/customs-clearance`,
    method: 'GET',
  });

  return result.data;
};

export function getClearanceQuery(): UseQueryResult<
  GetClearanceOutput,
  AxiosError<GetClearanceOutput>
> {
  return useQuery('clearance', () => getClearance(), { suspense: false });
}

export default function useGetClearance() {
  const {
    isLoading,
    data: clearanceData,
    refetch,
    error,
  } = getClearanceQuery();

  return {
    isGetClearanceLoading: isLoading,
    clearanceData,
    refetchClearance: refetch,
    error,
  };
}
