import styled from 'styled-components';

export const Container = styled.button`
  background-color: #1a7fcd;
  width: 100%;
  height: 46px;
  border-radius: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 32px 0px 50px 0px;

  &:disabled {
    background-color: #ccc;
  }
`;

export const ButtonTitle = styled.div`
  color: white;
  font-size: 15px;
  font-weight: 700;
`;
