import styled from 'styled-components';

export const FormContainer = styled.div`
  background-color: #f1f1f1;
  margin-top: 8px;
  width: 100%;
  height: 46px;
  border-radius: 23px;
  padding: 0px 24px 0px 24px;
  display: flex;
  align-items: center;
`;

export const Input = styled.input`
  width: 100%;
  font-size: 15px;
  ::placeholder {
    color: #999;
  }
`;
