import { FieldError, UseFormRegisterReturn } from 'react-hook-form';
import QuestionTitle from '../FormTitle';
import FormInput from '../FormInput';

import * as S from './_styled';

type Props = {
  title: string;
  required: boolean;
  example: string;
  placeHolder: string;
  register: UseFormRegisterReturn;
  errors?: FieldError;
  inputType?: 'text' | 'email' | 'password';
};

export default function QuestionForm({
  register,
  title,
  required,
  example,
  placeHolder,
  errors,
  inputType = 'text',
}: Props) {
  return (
    <S.Container>
      <S.TitleContainer>
        <QuestionTitle
          title={title}
          example={`Example : ${example}`}
          required={required}
        />
      </S.TitleContainer>
      <FormInput
        register={register}
        placeHolder={placeHolder}
        inputType={inputType}
      />
      {errors?.message && (
        <S.InputErrorMessage>{errors.message}</S.InputErrorMessage>
      )}
    </S.Container>
  );
}
