import styled from 'styled-components';

export const Container = styled.h1`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 70px;
  width: 100%;
  margin-top: 24px;
`;
