import * as S from './_styled';

type Props = {
  title: string;
  type: 'button' | 'submit' | 'reset';
  disabled: boolean;
  onClick?: () => void;
};

export default function SubmitButton({
  title,
  type,
  disabled,
  onClick,
}: Props) {
  return (
    <S.Container type={type} disabled={disabled} onClick={onClick}>
      <S.ButtonTitle>{title}</S.ButtonTitle>
    </S.Container>
  );
}
