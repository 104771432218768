import styled from 'styled-components';

type Props = {
  title: string;
  value: string;
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Title = styled.div`
  color: #767676;
`;

const Value = styled.div``;

export default function RegisterInfoItem({ title, value }: Props) {
  return (
    <Container>
      <Title>{title}</Title>
      <Value>{value}</Value>
    </Container>
  );
}
